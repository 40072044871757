import React from 'react';
import HomeSingleFeature from '../../../components/HomeSingleFeature/HomeSingleFeature';
import { FiBook, FiLock} from 'react-icons/fi';
import { FaLightbulb, FaRibbon } from 'react-icons/fa';
import { BsHouseDoor } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import webData from '../../../data/webData.json';

const HomeFeatures = () => {
   return (
      <>
         <section className="features__area pb-100 mt--100 wow fadeInUp" data-wow-delay=".5s">
            <div className="container">
               <div className="features__inner fix">
                  <div className="row g-0 ">

                     <HomeSingleFeature icon={<FiBook />} title={webData.services.integratedPhysicalSecurity[0].title} service={`/servicesDetails/${1}`} pic={webData.services.integratedPhysicalSecurity[0].pic} />
                     <HomeSingleFeature icon={<FaLightbulb />} title={webData.services.integratedPhysicalSecurity[1].title} service={`/servicesDetails/${2}`} pic={webData.services.integratedPhysicalSecurity[1].pic} />
                     <HomeSingleFeature icon={<BsHouseDoor />} title={webData.services.integratedPhysicalSecurity[2].title} service={`/servicesDetails/${3}`} pic={webData.services.integratedPhysicalSecurity[2].pic} />
                     <HomeSingleFeature icon={<IoDocumentTextOutline />} title={webData.services.integratedPhysicalSecurity[3].title} service={`/servicesDetails/${4}`} pic={webData.services.integratedPhysicalSecurity[3].pic} />
                     <HomeSingleFeature icon={<FiLock />} title={webData.services.integratedPhysicalSecurity[4].title} service={`/servicesDetails/${5}`} pic={webData.services.integratedPhysicalSecurity[4].pic} />
                     <HomeSingleFeature icon={<FaRibbon />} title={webData.services.integratedPhysicalSecurity[5].title} service={`/servicesDetails/${6}`} pic={webData.services.integratedPhysicalSecurity[5].pic} />

                  </div>
               </div>
            </div>
         </section>

      </>
   );
};

export default HomeFeatures;
