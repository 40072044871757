import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import webData from '../../../data/webData.json';

const HomeAbout = () => {
   const printableText = webData.aboutPage.aboutUs.replace(/(?:\r\n|\r|\n)/g, "<br />");

   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="/assets/img/about/about-1.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="/assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>{webData.whoWeAre.title}</span>
                           <h2>{webData.whoWeAre.subTitle}</h2>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: printableText}}></p>
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>{webData.whoWeAre.bullet1}</span></li>
                              <li ><span><i > <FaCheck/> </i>{webData.whoWeAre.bullet2}</span></li>
                              <li ><span><i > <FaCheck/> </i>{webData.whoWeAre.bullet3}</span></li>
                              <li ><span><i > <FaCheck/> </i>{webData.whoWeAre.bullet4}</span></li>
                           </ul>
                        </div>
                        <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;
