import React, { useState, useEffect } from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import ServicesDetailsArea from './ServicesDetailsArea/ServicesDetailsArea';
import ServicesDetailsHeader from './ServicesDetailsHeader/ServicesDetailsHeader';
import { useParams } from "react-router-dom";
import webData from '../../data/webData.json';

const ServicesDetails = () => {
    const { id } = useParams();
    const [service, setService] = useState({});

    useEffect(() => {
        const selectedService = webData.services.integratedPhysicalSecurity.find(service => parseInt(service.id) === parseInt(id));
        setService(selectedService);
        console.log(service);
    }, [id]);
   return (
      <>
         <PageHelmet pageTitle={`${service.title}`} />

         <HomeOneHeader />
         <ServicesDetailsHeader title={`${service.title}`} subTitle={`${service.subTitle}`}/>
         <ServicesDetailsArea details={`${service.details}`} heading={`${service.heading}`} image={`${service.pic}`}/>
         <Footer />
      </>
   );
};

export default ServicesDetails;
