import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const ServicesDetailsArea = ({ details, heading, image  }) => {
   const printableText = details.replace(/\r\n|\r|\n/g, "<br />");
    return (
        <>
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Our Services</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails/1">Home & Residential Security</Link></li>
                                     <li><Link to="/servicesDetails/2">Faith Based Security</Link></li>
                                     <li><Link to="/servicesDetails/3">Commercial Facilities & Office</Link></li>
                                     <li><Link to="/servicesDetails/8">Security Systems Design</Link></li>
                                     <li><Link to="/servicesDetails/9">Security Risk Management</Link></li>
                                     <li><Link to="/servicesDetails/10">Facilities Management Services</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="/#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="/#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div>
                      <div className="col-xl-8 col-lg-8">
                         <div className="services__text">
                            <h3>{heading}</h3>
                            <p dangerouslySetInnerHTML={{__html: printableText}}></p>
                         </div>
                         <div className="services__img mb-45 w-img">
                            <img src={`/assets/img/services/details/${image}`} alt="" />
                         </div>
                         {/*<div className="services__text">*/}
                         {/*   <h3>{heading2}</h3>*/}
                         {/*   <p>{details2}</p>*/}
                         {/*</div>*/}
                         {/*<div className="services__list mb-40">*/}
                         {/*   <ul>*/}
                         {/*      <li>Set up in minutes</li>*/}
                         {/*      <li>Save time with Automations</li>*/}
                         {/*      <li>Visualize work with Views</li>*/}
                         {/*      <li>Customer support</li>*/}
                         {/*   </ul>*/}
                         {/*</div>*/}
                         {/*<div className="services__text">*/}
                         {/*   <h4 >{heading3}*/}
                         {/*   </h4>*/}
                         {/*   <p>{details3}.</p>*/}
                         {/*</div>*/}
                      </div>
                   </div>
                </div>
          </section>
        </>
    );
};

export default ServicesDetailsArea;
