import React from 'react';
import webData from '../../../data/webData.json';

const HomeContact = () => {
   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        <iframe title='contact' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d127638.49800379062!2d36.9059882!3d-1.3547489!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11af9e4bf357%3A0x7c987dd1ae3da749!2sCapital%20Centre!5e0!3m2!1sen!2ske!4v1684313080133!5m2!1sen!2ske"></iframe>
                        <div className="contact__wrapper d-md-flex justify-content-between">
                           <div className="contact__info mr-100">
                              <h3>Get in touch</h3>
                              <ul>
                                 <li>
                                    <h4>Address</h4>
                                    <p>{webData.header.address}</p>
                                 </li>
                                 <li>
                                    <h4>call us</h4>
                                    <p><a href="tel:(+254) 722 760 661">{webData.header.phone}</a></p>
                                    <p><a href="tel:(+254) 722 760 661">{webData.header.landline}</a></p>
                                    <p><a href="tel:(+254) 722 760 661">{webData.header.altPhone}</a></p>
                                 </li>
                                 <li>
                                    <h4>Email Address</h4>
                                    <p><a href="mailto:info@protechtsecurity.co.ke">{webData.header.email}</a></p>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form action="#">
                                 <input type="text" placeholder="Your Name"/>
                                    <input type="email" placeholder="Your Email"/>
                                       <textarea placeholder="Your Message"></textarea>
                                       <button type="button" className="z-btn " >Send Message</button>
                                    </form>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
      </>
   );
};

export default HomeContact;
