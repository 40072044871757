import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';
import webData from '../../../data/webData.json';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>We offer a comprehensive range of services that cater to your unique security needs.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {
                     webData.services.integratedPhysicalSecurity.filter(e => e.id <= 8).map((e) => (
                        <SingleService key={e.id} icon={e.id} title={e.title} service={`/servicesDetails/${e.id}`} />
                     ))
                  }

                  {/*<SingleService icon="1" title={webData.services.integratedPhysicalSecurity[0].title} service={`/servicesDetails/${1}`}/>*/}
                  {/*<SingleService icon="2" title={webData.services.integratedPhysicalSecurity[1].title} service={`/servicesDetails/${2}`}/>*/}
                  {/*<SingleService icon="3" title={webData.services.integratedPhysicalSecurity[2].title} service={`/servicesDetails/${3}`}/>*/}
                  {/*<SingleService icon="4" title={webData.services.integratedPhysicalSecurity[3].title} service={`/servicesDetails/${4}`}/>*/}
                  {/*<SingleService icon="5" title={webData.services.integratedPhysicalSecurity[4].title} service={`/servicesDetails/${5}`}/>*/}
                  {/*<SingleService icon="6" title={webData.services.integratedPhysicalSecurity[5].title} service={`/servicesDetails/${6}`}/>*/}
                  {/*<SingleService icon="7" title={webData.services.integratedPhysicalSecurity[6].title} service={`/servicesDetails/${7}`}/>*/}
                  {/*<SingleService icon="8" title={webData.services.integratedPhysicalSecurity[7].title} service={`/servicesDetails/${8}`}/>*/}

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
